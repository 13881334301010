import { MAPS_DEFAULT_LOCATION_FALLBACK } from '../../../constants';

import { CityMapStyle, CityMapTheme } from './types';

export const CITY_MAPS_STYLE_LIST: CityMapStyle[] = ['overlay', 'heart'];

export const CITY_MAPS_DEFAULT_DATA = {
  maxLengthHeadline: 120,
  maxLengthSubtitle: 90,
};

export const CITY_MAPS_THEME_LIST = [
  {
    label: 'Old School',
    id: 'oldschool',
    mapboxId: 'ckyadc4o6b1mv15pcenwn5e2g',
  },
  {
    label: 'Classic',
    id: 'mono',
    mapboxId: 'ckxzxd58wg0pp15qzf7faq3z8',
  },
  {
    label: 'Dusk',
    id: 'asphalt',
    mapboxId: 'cky1dirpw2aef15pccvfto709',
  },
];

export const CITY_MAPS_GOLDEN_RATIO = 0.667;

export const CITY_MAPS_MAP_WRAPPER_ID = 'mapWrapper';
export const CITY_MAPS_DEFAULT_ZOOM = 10;

export const MAPBOX_ACCOUNT = 'anthony-pictarine';
export const MAPBOX_URL = 'https://api.mapbox.com/styles/v1/';
export const GEOCODE_API_URL = 'https://app.geocodeapi.io/api/v1/autocomplete';
export const MAPBOX_BASE_THEME_URL = 'mapbox://styles/anthony-pictarine/';

export const CITY_MAPS_DEFAULT_RATIO = 7;

export const CITY_MAPS_LOTTIE_SIZE = 250;

export const CITY_MAPS_DEFAULT_OPTIONS = {
  location: MAPS_DEFAULT_LOCATION_FALLBACK,
  theme: CITY_MAPS_THEME_LIST[0] as CityMapTheme,
  style: CITY_MAPS_STYLE_LIST[0] as CityMapStyle,
  headline: '',
  subtitle: '',
  coordinates: '',
};

export const CITYMAP_BASE_STYLE = {
  width: '100%',
  left: '50%',
  top: '50%',
  position: 'absolute',
  objectFit: 'cover',
  height: 'auto',
};
export const CITYMAP_CLASSIC_STYLE = {
  padding: '1rem',
  transform: 'translate(-50%, -50%)',
};
export const CITYMAP_STYLE_HEART = {
  transform: 'translate(-50%, -50%) scale(0.98)',
  zIndex: '1',
};
