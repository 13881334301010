'use client';

import { sendLog } from '@/services/logs';
import { Group } from '@/types/products';

import styles from './styles.module.scss';

export default function AnchorButton({ group }: { group: Group }) {
  return (
    <button
      className={styles.button}
      type="button"
      onClick={() => {
        sendLog('ui_homepage_banner_productTapped', {
          id: group.id,
        });
        document.getElementById(group.id)?.scrollIntoView({
          behavior: 'smooth',
        });
      }}
    >
      <span>{group.labels.name}</span>
    </button>
  );
}
