import { CITY_MAPS_GOLDEN_RATIO } from '@/components/wallArts/CustomCreative/Posters/CityMap/constants';
import { Group, Product } from '@/types/products';

// Get the product with the nearest city poster ratio and the lower price
const getDefaultWallArtProduct = (products: Product[], groups: Group[]) => {
  const posterProductIds = groups
    .find((group) => group.id.includes('poster'))
    ?.products.map(({ id }) => id);

  return products
    .filter(({ id }) => posterProductIds?.includes(id))
    .reduce((acc, product) => {
      const productFirstTier = product.price.tiers[0];
      const accFirstTier = acc?.price.tiers[0];

      if (
        acc &&
        productFirstTier &&
        accFirstTier &&
        Math.abs(
          product.specs.size.min / product.specs.size.max -
            CITY_MAPS_GOLDEN_RATIO,
        ) <=
          Math.abs(
            acc.specs.size.min / acc.specs.size.max - CITY_MAPS_GOLDEN_RATIO,
          ) &&
        productFirstTier.unitPrice < accFirstTier.unitPrice
      )
        return product;
      if (
        acc &&
        Math.abs(
          product.specs.size.min / product.specs.size.max -
            CITY_MAPS_GOLDEN_RATIO,
        ) <=
          Math.abs(
            acc.specs.size.min / acc.specs.size.max - CITY_MAPS_GOLDEN_RATIO,
          )
      )
        return product;

      return acc;
    }, products[0]);
};

export default getDefaultWallArtProduct;
