'use client';

import { useMemo } from 'react';
import clsx from 'clsx';

import { CARD_GROUP_ID } from '@/app/(Shop)/cards/types/cards';
import Icon from '@/components/Icon';
import { Product } from '@/types/products';
import getProductLowerPrice from '@/utils/products/getProductLowerPrice';
import getProductsLowerUnitPrice from '@/utils/products/getProductsLowerUnitPrice';
import productHasDecreasingPrices from '@/utils/products/productHasDecreasingPrices';

import styles from './styles.module.scss';

interface Props {
  product: Product;
  products?: Product[];
  hasExactPrice?: boolean;
}

export default function ProductPrice({
  product,
  products,
  hasExactPrice = true,
}: Props) {
  const hasDecreasingPrices = productHasDecreasingPrices(product);

  const price = useMemo(() => {
    if (products?.length) {
      return getProductsLowerUnitPrice(products);
    }
    return getProductLowerPrice(product);
  }, [product, products]);

  return price !== 0 ? (
    <span className={clsx(styles.price)} data-testid="price">
      {`${!hasExactPrice || hasDecreasingPrices ? 'From ' : ''}$${price}${product.id.includes(CARD_GROUP_ID) ? '/Card (Bundle)' : ''}
      `}
      {hasDecreasingPrices ? (
        <span className={clsx(styles.informationIcon)}>
          <Icon name="informationCircle" />
        </span>
      ) : null}
    </span>
  ) : null;
}
