'use client';

import * as React from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import clsx from 'clsx';

import Icon from '../Icon';

import styles from './styles.module.scss';

const Dialog = DialogPrimitive.Root;

const DialogClose = DialogPrimitive.Close;

function DialogTrigger({
  children,
  className,
  ...props
}: DialogPrimitive.DialogTriggerProps) {
  return (
    <DialogPrimitive.Trigger
      {...props}
      className={clsx(styles.trigger, className)}
    >
      {children}
    </DialogPrimitive.Trigger>
  );
}

function DialogPortal({
  children,
  className,
  ...props
}: DialogPrimitive.DialogPortalProps & { className?: string }) {
  return (
    <DialogPrimitive.Portal {...props}>
      <div className={clsx(styles.portal, className)}>{children}</div>
    </DialogPrimitive.Portal>
  );
}

DialogPortal.displayName = DialogPrimitive.Portal.displayName;

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={clsx(styles.overlay, className)}
    {...props}
  />
));
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & {
    // TODO: Change into enum when we have all options
    size?: 'auto' | 'fullscreen' | 'small' | 'fitContent';
    withCloseButton?: boolean;
    close?: boolean;
    // TODO: Change into enum when we have all options
    placement?: 'phoneBottom' | 'centered' | 'onlyBottom';
    classNamePortal?: string;
  }
>(
  (
    {
      className,
      children,
      size = 'auto',
      withCloseButton = true,
      placement = 'centered',
      classNamePortal,
      ...props
    },
    ref,
  ) => (
    <DialogPortal className={classNamePortal}>
      <DialogOverlay />
      <DialogPrimitive.Content
        ref={ref}
        className={clsx(
          styles.content,
          styles[size],
          styles[placement],
          className,
        )}
        {...props}
      >
        {children}
        {withCloseButton && (
          <DialogPrimitive.Close className={styles.close}>
            <Icon name="removeCross" />
          </DialogPrimitive.Close>
        )}
      </DialogPrimitive.Content>
    </DialogPortal>
  ),
);
DialogContent.displayName = DialogPrimitive.Content.displayName;

function DialogHeader({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return <div className={clsx(styles.header, className)} {...props} />;
}

DialogHeader.displayName = 'DialogHeader';

function DialogFooter({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return <div className={clsx(styles.footer, className)} {...props} />;
}

DialogFooter.displayName = 'DialogFooter';

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={clsx(styles.title, className)}
    {...props}
  />
));
DialogTitle.displayName = DialogPrimitive.Title.displayName;

const DialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    className={clsx(styles.description, className)}
    {...props}
  />
));
DialogDescription.displayName = DialogPrimitive.Description.displayName;

export {
  Dialog,
  DialogTrigger,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
};
