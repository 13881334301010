import { Product } from '@/types/products';
import sortPriceTiersAsc from '@/utils/products/sortPriceTiersAsc';

import styles from './styles.module.scss';

interface Props {
  product: Product;
}

export default function PricesInformation({ product }: Props) {
  const { tiers } = product.price;
  return (
    <div className={styles.pricesPopupContainer}>
      <h2 className={styles.title}>
        {product.labels.shortName} Quantity Discount
      </h2>
      <p className={styles.description}>
        {`${product.labels.shortName} prints are cheaper when you buy them in bulk!`}
      </p>
      <div className={styles.container}>
        {sortPriceTiersAsc(tiers).map((tier, index) => (
          <div key={`Tier${index + 1}`} className={styles.priceItem}>
            <span className={styles.priceQuantity}>{`${tier.from}+`}</span>
            <span className={styles.price}>${tier.unitPrice}</span>
            <span className={styles.priceDescription}>per print</span>
          </div>
        ))}
      </div>
    </div>
  );
}
