'use client';

import { PropsWithChildren, useEffect, useRef, useState } from 'react';

import { PERCENTAGE_VISIBLE_TO_SEND_LOG } from '@/constants';
import useIntersectionObserver from '@/hooks/useIntersectionObserver';
import { sendLog } from '@/services/logs';
import { Product } from '@/types/products';

interface Props {
  product: Product;
}

function ProductIntersectionLogger({
  children,
  product,
}: PropsWithChildren<Props>) {
  const [isProductViewedLogSent, setIsSectionViewedLogSent] = useState(false);

  const productRef = useRef<HTMLDivElement>(null);

  const entry = useIntersectionObserver(productRef, {
    freezeOnceVisible: true,
    threshold: PERCENTAGE_VISIBLE_TO_SEND_LOG,
  });
  const isProductVisible = !!entry?.isIntersecting;

  useEffect(() => {
    if (isProductVisible && !isProductViewedLogSent) {
      sendLog('ui_product_viewed', {
        page: 'index',
        id: product.id,
      });
      setIsSectionViewedLogSent(true);
    }
  }, [isProductVisible, product.id, isProductViewedLogSent]);

  return <div ref={productRef}>{children}</div>;
}

export default ProductIntersectionLogger;
