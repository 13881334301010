import styles from './styles.module.scss';

export default function Loading() {
  return (
    <div className={styles.termsContainer}>
      <p className={styles.text}>
        By using this website, I agree to be bound by the
        <span className={styles.mentionsTerms}> Terms of Use</span> and
        <span className={styles.mentionsTerms}> Privacy Policy</span>
      </p>
    </div>
  );
}
