import { Template } from '@/types/template';

export const CARD_GROUP_ID = 'card';

export interface Universe {
  id: string;
  name: string;
  sections: {
    id: number;
    name: string;
  }[];
  trending?: Trending;
  collections: UniverseCollection[];
}

export interface Trending {
  id: string;
  name: string;
  previewUrl: string;
  designs: Design[];
  tags: unknown;
  filters: unknown;
}

export interface CardCollection {
  id: string;
  contentHeader?: {
    description: string;
    imageUrl: string;
    title: string;
    subdescription?: string;
  };
  name: string;
  previewUrl: string;
  previewAnimateUrl: string;
  designs: Design[];
  filters: CardFilter[];
}

interface CardFilterApi extends Omit<CardFilter, 'tags'> {
  tagIds: number[];
}

export interface CardCollectionAPI extends Omit<CardCollection, 'filters'> {
  tags: CardFilterTag[];
  filters: CardFilterApi[];
}

export interface CardFilterTag {
  id: number;
  name: string;
  checked?: boolean;
}

export interface UniverseCollection extends Omit<CardCollection, 'filters'> {
  sectionId: number;
  homepagePreviewUrl?: string;
}

export interface ResultSearchSuggestions {
  suggestions: Suggestion[];
  defaultDesigns: DefaultDesign[];
}

export interface Suggestion {
  collection?: CardCollection;
  tag?: {
    id: number;
    name: string;
  };
  text: string;
}

export interface DefaultDesign {
  designer: Designer;
  licence: License;
  id: number;
  name: string;
  previewUrl: string;
  previewAnimateUrl: string;
  catalogPreviewUrl: string;
  tagsIds: [];
  templates: Template[];
}

interface Designer {
  id: string;
  name: string;
}

interface License {
  id: string;
  name: string;
  legalLines?: string;
}

export interface Design {
  id: string;
  name: string;
  previewUrl: string;
  previewUrls: string[];
  templates: Template[];
  tagIds: number[];
  license?: License;
  catalogPreviewUrl: string;
}

export interface CardFilter {
  tags: CardFilterTag[];
  type: {
    id: number;
    category: string;
    singleSelect: boolean;
    priority: number;
  };
}

export interface Tags {
  collections: CardCollection[];
}

export enum CardTagDisplayZone {
  'allFiltersPopup',
  'filterBar',
}

export enum LayoutType {
  oneColumn = 'oneColumn',
  twoColumns = 'twoColumns',
}

export interface DesignViewType {
  id: string;
  name: string;
  previewUrl?: string;
  templates: Template[];
  catalogPreviewUrl: string;
}
