'use client';

import { PropsWithChildren, useEffect, useRef, useState } from 'react';

import useIntersectionObserver from '@/hooks/useIntersectionObserver';
import { sendLog } from '@/services/logs';
import sendGTMLog from '@/services/logs/sendGTMLog';
import { GTMLogEvent } from '@/services/logs/types';
import { Group as GroupType, Product } from '@/types/products';

interface Props {
  group: GroupType;
  products: Product[];
}

export default function ProductCategoriesIntersectionLogger({
  group,
  products,
  children,
}: PropsWithChildren<Props>) {
  const [sectionViewed, setSectionViewed] = useState(false);

  const sectionRef = useRef<HTMLDivElement>(null);

  const entry = useIntersectionObserver(sectionRef, {
    freezeOnceVisible: true,
    threshold: 0.8,
  });

  useEffect(() => {
    if (entry?.isIntersecting && products && group && !sectionViewed) {
      // set section viewed with a set to avoid multiple calls
      setSectionViewed(true);
      sendLog('ui_section_viewed', { page: 'index', id: group.id });
      sendGTMLog({
        event: GTMLogEvent.viewItemList,
        value: { group, products },
      });
    }
  }, [entry?.isIntersecting, group.id, products, group, sectionViewed]);

  return (
    <div style={{ position: 'relative' }} ref={sectionRef}>
      {children}
    </div>
  );
}
