import { RETRO_ID } from '@/constants';
import { UploaderStatus } from '@/context/Uploader/types/image';
import {
  UploaderJob,
  UploaderJobStatusChangedCallback,
} from '@/context/Uploader/types/job';
import isImageStatusAdded from '@/context/Uploader/utils/status/isImageStatusAdded';
import { addTemplateItem } from '@/stores/Cart/CartStore';
import { Product } from '@/types/products';

import { CartItem } from '../../types';

import { retroPrintTemplate } from './retroPrintTemplate';

const addToCartRetroPrint = ({
  product,
  onAdded,
}: {
  product: Product;
  onAdded?: (cartItem: CartItem) => void;
}): UploaderJobStatusChangedCallback<UploaderStatus> => ({
  statuses: [UploaderStatus.Added],
  callback: (job: UploaderJob) => {
    job.images.forEach((image) => {
      if (isImageStatusAdded(image)) {
        const cartItem = addTemplateItem({
          template: retroPrintTemplate,
          productId: RETRO_ID,
          specs: product.specs,
          file: {
            id: image.id,
          },
        });
        onAdded?.(cartItem);
      }
    });
  },
});

export default addToCartRetroPrint;
