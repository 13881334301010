import { UploaderImage, UploaderStatus } from '@/context/Uploader/types/image';
import { UploaderImageStatusChangedCallback } from '@/context/Uploader/types/job';
import isImageStatusUploaded from '@/context/Uploader/utils/status/isImageStatusUploaded';
import { setCartItemImageUrl } from '@/stores/Cart/CartStore';

const updateCartItemImageUrl =
  (): UploaderImageStatusChangedCallback<UploaderStatus> => ({
    statuses: [UploaderStatus.Uploaded],
    callback: (image: UploaderImage<UploaderStatus>) => {
      if (isImageStatusUploaded(image)) {
        setCartItemImageUrl(image.id, image);
      }
    },
  });

export default updateCartItemImageUrl;
