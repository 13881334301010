import { Product } from '@/types/products';
import getProductLowerUnitPrice from '@/utils/products/getProductLowerUnitPrice';

const getProductsLowerUnitPrice = (products: Product[]): number =>
  products.reduce<number | undefined>((acc, product) => {
    const productPrice = getProductLowerUnitPrice(product);
    if (acc === undefined || acc > productPrice) return productPrice;
    return acc;
  }, undefined) || 0;

export default getProductsLowerUnitPrice;
