import { UploaderImage, UploaderStatus } from '@/context/Uploader/types/image';
import { UploaderImageStatusChangedCallback } from '@/context/Uploader/types/job';
import isImageStatusPrepared from '@/context/Uploader/utils/status/isImageStatusPrepared';
import { setCartItemImageBlob } from '@/stores/Cart/CartStore';

const setCartItemImageBlobUrl =
  (): UploaderImageStatusChangedCallback<UploaderStatus> => ({
    statuses: [UploaderStatus.Prepared],
    callback: (image: UploaderImage<UploaderStatus>) => {
      if (isImageStatusPrepared(image)) {
        setCartItemImageBlob(image.id, image);
      }
    },
  });

export default setCartItemImageBlobUrl;
