import { UploaderImage, UploaderStatus } from '@/context/Uploader/types/image';
import { UploaderImageStatusChangedCallback } from '@/context/Uploader/types/job';
import isImageStatusUploaded from '@/context/Uploader/utils/status/isImageStatusUploaded';

import { setRetroPrintTemplateImageUrl } from '../../CartStore';

const updateRetroPrintImageUrl =
  (): UploaderImageStatusChangedCallback<UploaderStatus> => ({
    statuses: [UploaderStatus.Uploaded],
    callback: (image: UploaderImage<UploaderStatus>) => {
      if (isImageStatusUploaded(image)) {
        setRetroPrintTemplateImageUrl(image.id, image);
      }
    },
  });

export default updateRetroPrintImageUrl;
